<template>
    <div>
        <h1 class="page-title mb-10">Profileinstellungen</h1>

        <v-form ref="profileform" @submit.prevent="updateProfile">
            <h3 class="mb-3 mt-5">In Chats & Diskussionen</h3>
            <v-radio-group v-model="displayName.style">
                <v-radio value="initials">
                    <template #label>
                        <div>
                            Zeige nur meine Initialen an: <strong>{{ displayName.initials }}</strong>
                        </div>
                    </template>
                </v-radio>
                <v-radio value="nameShort">
                    <template #label>
                        <div>
                            Zeige meinen Namen gekürzt an: <strong>{{ displayName.nameShort }}</strong>
                        </div>
                    </template>
                </v-radio>
                <v-radio value="nameFull">
                    <template #label>
                        <div>
                            Zeige meinen vollständigen Namen an: <strong>{{ displayName.nameFull }}</strong>
                        </div>
                    </template>
                </v-radio>
                <v-radio value="nickname">
                    <template #label>
                        <div>
                            Zeige meinen Spitznamen an: <strong>{{ displayName.nickname }}</strong>
                        </div>
                    </template>
                </v-radio>
            </v-radio-group>
            <v-text-field
                v-model="displayName.nickname"
                outlined
                label="Spitzname"
                :rules="displayName.style === 'nickname' ? [formRules.required('Dein Spitzname')] : []"
            />

            <h3 class="mb-3 mt-5">Stamm/Gruppe</h3>
            <v-text-field v-model="tribe" outlined label="Stamm/Gruppe" />

            <h3 class="mb-3 mt-5">E-Mail-Adresse ändern</h3>
            <v-text-field
                v-model="email"
                outlined
                label="E-Mail-Adresse"
                :placeholder="!autofilled ? ' ' : null"
                autocomplete="new-password"
                :rules="email !== '' ? [formRules.valid.email] : []"
            />

            <h3 class="mb-3 mt-5">Passwort ändern</h3>
            <v-text-field
                v-model="password.current.value"
                required
                outlined
                autocomplete="new-password"
                label="Aktuelles Passwort"
                :placeholder="!autofilled ? ' ' : null"
                :rules="password.new.value !== '' ? [formRules.required('Dein Passwort')] : []"
                :append-icon="password.current.show ? 'fa-eye' : 'fa-eye-slash'"
                :type="password.current.show ? 'text' : 'password'"
                @click:append="toggleShowCurrentPassword()"
            />
            <v-text-field
                v-model="password.new.value"
                required
                outlined
                autocomplete="new-password"
                label="Neues Passwort"
                :placeholder="!autofilled ? ' ' : null"
                :rules="password.current.value !== '' ? [formRules.required('Dein Passwort')] : []"
                :append-icon="password.new.show ? 'fa-eye' : 'fa-eye-slash'"
                :type="password.new.show ? 'text' : 'password'"
                @click:append="toggleShowNewPassword()"
            />

            <v-btn :loading="loading" color="primary" large block depressed class="mb-5 mt-10" type="submit">
                Speichern
            </v-btn>
            <v-btn @click="close()" depressed block text>Abbrechen</v-btn>
        </v-form>

        <app-selection-dialog v-if="showAppSelection" :apps="apps" />
    </div>
</template>

<script>
import api from "../plugins/api";
import { sendMessage } from "../plugins/messageBus";
import formRules from "../plugins/formRules";
import AppSelectionDialog from "../components/AppSelectionDialog.vue";

export default {
    name: "ProfileSettings",

    components: {
        AppSelectionDialog,
    },

    data: () => ({
        autofilled: false,
        displayName: {
            style: "nameShort",
            initials: "MM",
            nameShort: "Max M.",
            nameFull: "Max Mustermann",
            nickname: "",
        },
        tribe: "",
        email: "",
        password: {
            current: {
                value: "",
                show: false,
            },
            new: {
                value: "",
                show: false,
            },
        },
        showPassword: false,
        formRules,
        loading: false,
        apps: [],
        showAppSelection: false,
    }),

    watch: {
        email() {
            this.autofilled = true;
        },
    },

    mounted() {
        this.loadUser();
    },

    methods: {
        async loadUser() {
            const user = await api.getUser();
            this.displayName.style = user.displayName;
            this.displayName.nickname = user.nickname;
        },

        toggleShowCurrentPassword() {
            this.password.current.show = !this.password.current.show;
        },
        toggleShowNewPassword() {
            this.password.new.show = !this.password.new.show;
        },

        async close() {
            this.$router.go(-1);
        },

        async updateProfile() {
            if (!this.$refs.profileform.validate()) return;

            this.loading = true;
            try {
                await api.updateProfile({
                    ...(this.email && { email: this.email }),
                    ...(this.tribe && { tribe: this.tribe }),
                    ...(this.password.current.value && { currentPassword: this.password.current.value }),
                    ...(this.password.new.value && { newPassword: this.password.new.value }),
                    ...(this.displayName.style && { displayName: this.displayName.style }),
                    ...(this.displayName.nickname && { nickname: this.displayName.nickname }),
                });
                this.close();
            } catch (error) {
                sendMessage(
                    "Dein Profil konnte leider nicht aktualisiert werden. Bitte versuche es später erneut.",
                    "error",
                );
            }
            this.loading = false;
        },
    },
};
</script>
